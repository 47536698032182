import React from 'react';
import { Select as SelectWrapper } from 'antd';
import Typography from '../Typography';

import './Select.less';

const { Option } = SelectWrapper;

export default function Select({
  size,
  disabled,
  onChange,
  value,
  options,
  placeholder,
  required,
  styleRequired,
  styleSelect = {},
  errorMessage,
  viewValue = false,
}) {
  return (
    <div
      className={`${
        Object.keys(styleSelect).length > 0
          ? 'fanthon-select-checkout'
          : 'fanthon-select'
      }`}
    >
      <Typography type="h9" style={{ display: 'flex', alignItems: 'center' }}>
        {required ? (
          styleRequired ? (
            <span className="required-star">*</span>
          ) : (
            <div
              style={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                background: '#D74039',
              }}
            />
          )
        ) : (
          <></>
        )}
        {placeholder}
      </Typography>
      <SelectWrapper
        style={styleSelect}
        showSearch
        allowClear
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={(value) => onChange(value)}
        defaultValue={value}
        dropdownClassName="fanthon-dropdown"
        filterOption={(input, { props }) =>
          props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options?.map(({ name, value }) => (
          <Option key={value} value={value}>
            {!viewValue ? name : value}
          </Option>
        ))}
      </SelectWrapper>
      {errorMessage && (
        <div>
          <Typography type="h9" style={{ color: '#D74039' }}>
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
}
