import React, { useEffect, useState } from 'react';
import { Input as InputWrapper } from 'antd';
import MaskedInput from 'react-text-mask';
import Typography from '../Typography';

import './InputMask.less';

export default function InputMask({
  size,
  disabled,
  onChange,
  value,
  placeholder,
  mask,
  required,
  styleRequired,
  errorMessage,
  maxLength,
  regexReplace,
  styleInput = {},
}) {
  const [maskedValue, setMaskedValue] = useState(value);

  useEffect(() => {
    setMaskedValue(value);

    return () => {
      setMaskedValue(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mask) return <div />;
  return (
    <div
      className={`${
        Object.keys(styleInput).length > 0
          ? 'fanthon-inputmask-checkout'
          : 'fanthon-inputmask'
      }`}
    >
      <Typography type="h9" style={{ display: 'flex', alignItems: 'center' }}>
        {required ? (
          styleRequired ? (
            <span className="required-star">*</span>
          ) : (
            <div
              style={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                background: '#D74039',
              }}
            />
          )
        ) : (
          <></>
        )}
        {placeholder}
      </Typography>
      <MaskedInput
        guide={false}
        mask={mask}
        showMask
        disabled={disabled}
        size={size}
        render={(ref, props) => (
          <InputWrapper
            style={styleInput}
            allowClear
            value={maskedValue}
            placeholder={placeholder}
            maxLength={maxLength}
            status={errorMessage && 'error'}
            ref={(input) => ref(input && input.input)}
            {...props}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onChange(
                event.target.value.replace(new RegExp(regexReplace), ''),
              );
            }}
            onBlur={(event) => {
              props.onChange(event.target.value);
              setMaskedValue(event.target.value);
            }}
            onChange={(event) => {
              const unMaskedValue = event.target.value
                .toString()
                .replace(/-/gi, '')
                .replace(/\//gi, '')
                .replace(/\./gi, '')
                .replace(/ /gi, '');

              props.onChange(unMaskedValue);
              setMaskedValue(event.target.value);
            }}
          />
        )}
      />
      {errorMessage && (
        <div>
          <Typography type="h9" style={{ color: '#D74039' }}>
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
}
