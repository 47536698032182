/* eslint-disable jsx-a11y/alt-text */
import React, { Suspense, useEffect, useState } from 'react';
import { Loading, Layout, Header, LayoutContent, Routing } from './components';
import { Skeleton, notification } from 'antd';
import { BrowserRouter, Switch } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import numeral from 'numeral';
import moment from 'moment';
import 'numeral/locales';
import { DEFAULT } from './constants/proporsal';
import { ROUTES } from './config/routes';
import { useLocalStorage } from './hooks/useLocalStorage';
import { getAmountOfMonths } from './utils/service';
import Api from './services/api';
import './App.less';

const { Footer } = Layout;

const Page404 = React.lazy(() => import('./pages/Page404'));
numeral.locale('pt-br');

const CONVERT_FREQUENCY_TO_VIGENCE = {
  ONCE: 'ONCE',
  MONTH: 'MONTH',
  QUARTER: 'QUARTERLY',
  SEMESTER: 'SEMESTER',
  YEAR: 'ANNUAL',
};

const { MONTH: PERIOD } = CONVERT_FREQUENCY_TO_VIGENCE;
const today = moment(new Date()).startOf('day');

function App() {
  const [type, setType] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const { href: link = '' } = window.location;
  const isLinkError = link.includes('error');

  const { WEBLINK_HOTJAR } = process.env;
  const siteId = WEBLINK_HOTJAR;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  // eslint-disable-next-line no-unused-vars
  const [_version, _company, _host, slugName, linkId, contractId] =
    link.split('/');
  useEffect(() => {
    if (!linkId) return;
    setType(linkId === 'checkout' ? 'contract' : 'service');
  }, [linkId]);

  const [data, setData] = useLocalStorage(
    `subscriptionData-${slugName}`,
    DEFAULT,
  );

  const [serviceData, setServiceData] = useLocalStorage('service', {});
  const [contractData, setContractData] = useLocalStorage('contract', {});

  const openNotification = (message = 'Salvo com sucesso!', description) => {
    const args = {
      message,
      description,
    };
    notification.open(args);
  };

  const onChange = (event) => setData({ ...data, ...event });
  const onChangeLevel = (field, event) =>
    setData({ ...data, [field]: { ...data[field], ...event } });
  const onChangeContract = (contract) => {
    const { contract: contract_original, contract_items = [] } = data || {};
    const [service] = contract_items || [];
    const { validityValue, category, frequencyPayment } = service || {};

    const months = validityValue
      ? getAmountOfMonths(validityValue, PERIOD)
      : 12;
    const newDate = today.clone();
    const invoiceDay = newDate.format('DD');
    const preset =
      contract?.invoiceDay || contract_original?.invoiceDay || invoiceDay;
    let initialDate =
      category === 'TOTAL_SERVICE'
        ? newDate.clone().date(preset)
        : newDate.clone();
    if (initialDate.isBefore(newDate))
      initialDate = initialDate.clone().add(1, PERIOD);
    contract.initialDate = initialDate.clone();

    contract.finalDate = contract?.initialDate
      .clone()
      .add(months, PERIOD)
      .endOf('day')
      .subtract(1, 'day');
    contract.vigenceType =
      CONVERT_FREQUENCY_TO_VIGENCE[frequencyPayment || PERIOD];
    contract.subtotal = contract_items.reduce(
      (acc, item) => acc + (item.amount * item.priceUnit || 0.0),
      0,
    );
    contract.taxes = contract_items.reduce(
      (acc, item) => acc + (item.amount * item.rate || 0.0),
      0,
    );
    contract.total = contract.subtotal + contract.taxes;

    onChangeLevel('contract', contract);
  };

  const addItem = async ({ id: service_id, company, ...service }) => {
    const { company_id, tenant_id, contract, contract_items = [] } = data || {};
    contract_items.push({
      ...service,
      tenant_id,
      company_id,
      service_id,
    });

    await onChange({ contract_items });
    onChangeContract({
      ...contract,
      tenant_id,
      company_id,
    });
  };

  const onReset = () => setData(DEFAULT);

  const fetchService = async (company_id) => {
    const response = await Api.service(company_id, linkId);
    if (!response.data) return;
    setServiceData(response?.data);
    setLoading(false);

    openNotification(
      'Carregado com sucesso!',
      'Todos os dados foram recuperados ;D',
    );
  };

  const fetchContract = async (company_id) => {
    const response = await Api.contract(company_id, contractId);
    if (!response.data) return;
    setContractData(response?.data);
    setLoading(false);

    openNotification(
      'Carregado com sucesso!',
      'Todos os dados foram recuperados ;D',
    );
  };

  const getLink = async () => {
    if (isLinkError) return;

    try {
      const company = await Api.company(slugName);
      if (!company) return;

      const { tenant_id, id: company_id, configurations } = company?.data || {};
      onChange({ tenant_id, company_id, configurations });
      setCompany(company?.data);
    } catch (err) {
      await localStorage.clear();
      window.location.href = '/error';
    }
  };

  useEffect(() => {
    localStorage.removeItem('fanthon-web-link_subscriptionData-error');
    setData(data);
    getLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!type || !data?.company_id) return;

    if (type === 'contract' && contractId) fetchContract(data?.company_id);
    if (type === 'service' && linkId) fetchService(data?.company_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, linkId, contractId, data?.company_id]);

  if (isLinkError || loading) return <Skeleton active />;

  const isService = !isLinkError && type === 'service';

  const noLayout = window.location.pathname.includes('checkoutPage');

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Layout className="App">
          {isService && !noLayout && (
            <Header routes={ROUTES} data={data} serviceData={serviceData} />
          )}

          <LayoutContent
            style={{ margin: isService ? '200px  0 100px 0' : 0 }}
            noLayout={!!noLayout}
          >
            <Switch>
              {ROUTES.map(({ path, title, component }) => (
                <Routing
                  key={path}
                  path={path}
                  component={component}
                  title={title}
                  exact={true}
                  struct={{
                    data,
                    company,
                    serviceData,
                    contractData,
                    onReset,
                    onChangeLevel,
                    onChangeContract,
                    addItem,
                    openNotification,
                    action: {
                      onChange,
                    },
                  }}
                />
              ))}

              <Routing path="/error" component={Page404} title="Erro" />
              <Routing path="*" component={Page404} title="" />
            </Switch>
          </LayoutContent>
        </Layout>
        {!noLayout && (
          <Footer style={{ textAlign: 'center' }}>
            powered by <img src="/logo192.png" width={24} /> ©2022.
          </Footer>
        )}
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
