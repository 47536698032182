import { lazy } from 'react';

const Service = lazy(() => import('../pages/Service'));
const CheckoutPage = lazy(() => import('../pages/CheckoutPage'));
const CustomerValid = lazy(() => import('../pages/CustomerValid'));
const CustomerNew = lazy(() => import('../pages/CustomerNew'));
const CustomerAddress = lazy(() => import('../pages/CustomerAddress'));
const CustomerAttachment = lazy(() => import('../pages/CustomerAttachment'));
const CustomerRegister = lazy(() => import('../pages/CustomerRegister'));
const ContractConfiguration = lazy(() =>
  import('../pages/ContractConfiguration'),
);
const ContractPayment = lazy(() => import('../pages/ContractPayment'));
const ContractTerm = lazy(() => import('../pages/ContractTerm'));
const ContractWitness = lazy(() => import('../pages/Witness'));
const ContractRegister = lazy(() => import('../pages/ContractRegister'));
const Result = lazy(() => import('../pages/Result'));
const CreditCardCheckout = lazy(() => import('../pages/CreditCardCheckout'));

export const ROUTES = [
  {
    path: '/:slugName/:linkId',
    component: Service,
    title: 'Serviço ou produto escolhido',
  },
  {
    path: '/:slugName/:linkId/checkoutPage',
    component: CheckoutPage,
    title: 'FanthonPay',
  },
  {
    path: '/:slugName/:linkId/customer',
    component: CustomerValid,
    title: 'Cadastro do cliente',
  },
  {
    path: '/:slugName/:linkId/customer/new',
    component: CustomerNew,
    title: 'Cadastro do cliente',
  },
  {
    path: '/:slugName/:linkId/customer/address',
    component: CustomerAddress,
    title: 'Cadastro do cliente',
  },
  {
    path: '/:slugName/:linkId/customer/attachment',
    component: CustomerAttachment,
    title: 'Cadastro do cliente',
  },
  {
    path: '/:slugName/:linkId/customer/register',
    component: CustomerRegister,
    title: 'Aguarde estamos criando seu cadastro',
  },
  {
    path: '/:slugName/:linkId/contract/configs',
    component: ContractConfiguration,
    title: 'Personalize a sua experiência',
  },
  {
    path: '/:slugName/:linkId/contract/payment',
    component: ContractPayment,
    title: 'Escolha a forma de Pagamento',
  },
  {
    path: '/:slugName/:linkId/contract/witness',
    component: ContractWitness,
    title: 'Insira uma testemunha para o contrato',
  },
  {
    path: '/:slugName/:linkId/contract/terms',
    component: ContractTerm,
    title: 'Tenha acesso ao nosso contrato',
  },
  {
    path: '/:slugName/:linkId/contract/register',
    component: ContractRegister,
    title: 'Aguarde falta pouco...',
  },
  {
    path: '/:slugName/:linkId/result',
    component: Result,
    title: 'Feito! Concluído o cadastro',
  },
  {
    path: '/:slugName/checkout/:contractId',
    component: CreditCardCheckout,
    title: 'Vamos realizar o pagamento!',
    hideHeader: true,
  },
];
